export const lightTheme = {
  name: 'light',
  foreground: "#EBEBEB",
  background: "#FFFFFF",
  buttonBackground: "#EBEBEB",
  activeButtonBackground: "#0D102F",
  inputBackground: "#D6D6D6",
  color: "#0D102F",
  activeColor: "#D9DCFB",
  hoverBackground: "#FFFFFF",
  hoverColor: "#4152EC",
  titleColor: "#343434",
  primary: "#0092e3",
  shadowColor: "rgba(0, 0, 0, 0.9)",
};

export const darkTheme = {
  name:'dark',
  foreground: "#141414",
  background: "#000000",
  buttonBackground: "#141414",
  activeButtonBackground: "#D9DCFB",
  inputBackground: "#292929",
  color: "#D9DCFB",
  activeColor: "#0D102F",
  hoverBackground: "#292929",
  hoverColor: "#4152EC",
  titleColor: "#CDCDCD",
  primary: "#0017e3",
  shadowColor: "rgba(255, 255, 255, 0.9)",
};
